
import { Money } from '../components/Money'

export const component = 'Money' // Component to extend, required
export const exported = '@graphcommerce/magento-store' // Location where the component is exported, required

const ListPlugin = (props) => {
  // Prev in this case is ProductListItem, you should be able to see this if you log it.
  const { Prev, ...rest } = props
  return (
    <Money
      {...rest}
    />
  )
}
export const Plugin = ListPlugin // An export with the name Plugin, required