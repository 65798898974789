import React from 'react'
type MainContentLayoutProps = React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    homeLayout?: boolean
  }

export default function MainContentLayout({
  children,
  homeLayout,
  className,
  ...props
}: MainContentLayoutProps) {
  return (
    <div className={`mt-48 max-992:mt-14 mx-auto !max-w-7xl ${className}`} {...props}>
      {children}
    </div>
  )
}
