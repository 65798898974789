import Link from 'next/link'
import IconDocument_list from '../../../icons/IconDocument_list'
import IconFilm from '../../../icons/IconFilm'
import { useEffect, useState } from 'react'
import { ArticleItemProcessor, ArticleSpecificType } from '../../../../lib/article'
import { VideoItemProcessor, VideoSpecificType } from '../../../../lib/video'
import { Video, VideoSkeleton } from '../../../Video'
import { Article, ArticleSkeleton } from '../../../Article'
import { uuid } from '../../../../lib/utils'

export default function BlogSection() {
  const [articleItems, setBlogItems] = useState<ArticleSpecificType[]>()
  const [videoItems, setVideoItems] = useState<VideoSpecificType[]>()

  useEffect(() => {
    fetch('/api/articles')
      .then((res) => res.json())
      .then((data) => {
        const processedData = ArticleItemProcessor.processItems(data)
        setBlogItems(processedData)
      })
      .catch((error) => console.error('Error fetching articles:', error))

    fetch('/api/videos')
      .then((res) => res.json())
      .then((data) => {
        const processedData = VideoItemProcessor.processItems(data)
        setVideoItems(processedData)
      })
      .catch((error) => console.error('Error fetching articles:', error))
  }, [])

  const blogArticle = 'https://ordme.com/blog/article'
  const blogVideos = 'https://ordme.com/blog/video/'

  return (
    <div className='w-full bg-light-80 box-border pb-24 pt-6 sm:px-6 992:px-0 max-md:pb-16 [&>*]:1170:!max-w-1170 [&>*]:!max-w-970 [&>*]:!mx-auto'>
      <div className='w-full flex flex-col gap-3 992:flex-row'>
        <div className='w-full 992:w-9/12'>
          <div className='h-12 w-full flex justify-between items-center mb-3 *:text-primary-500'>
            <Link href={blogArticle} className='text-sm'>
              <span className='flex items-center w-full'>
                <IconDocument_list width={46} height={46} />
                <h4 className='text-xl font-semibold'>در بلاگ اُردمی بخوانید</h4>
              </span>
            </Link>
            <Link href={blogArticle} className='text-sm font-medium'>
              + مشاهده همه
            </Link>
          </div>
          <div className='grid gap-3 grid-cols-1 992:grid-cols-3'>
            {!Array.isArray(articleItems) || !articleItems?.length
              ? Array(3)
                  .fill('')
                  .map((_) => <ArticleSkeleton key={uuid()} />)
              : articleItems?.map((article) => <Article key={article.id} {...article} />)}
          </div>
        </div>
        <div className='w-full 992:w-3/12 flex flex-col'>
          <div className='h-12 mb-3 w-full flex flex-row justify-between items-center text-primary-500'>
            <Link href={blogVideos} className='flex items-center font-semibold text-sm'>
              <IconFilm width={22} height={22} className='ml-2' />
              تازه‌های تصویری
            </Link>
            <Link href={blogVideos} className='text-sm font-medium'>
              + مشاهده همه
            </Link>
          </div>
          <div className='flex-1 flex gap-3 flex-col'>
            {!Array.isArray(videoItems) || !videoItems?.length
              ? Array(2)
                  .fill('')
                  .map((_) => <VideoSkeleton key={uuid()} />)
              : videoItems?.map((video) => <Video key={video.id} {...video} />)}
          </div>
        </div>
      </div>
    </div>
  )
}
