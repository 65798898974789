
import { Money } from "@graphcommerce/magento-store";
import { Trans } from "@lingui/react";
import Image from "next/image";
import Link from "next/link";
import { ProductWidgetsType } from "../../../../api/landingPage/widgets";
import MainContentLayout from "../../../customLayout/mainContentLayout";
import IconCheck2Circle from "../../../icons/IconCheck2Circle";
import ShowSwatchColorsOnPLP from "../../../ProductListItem/showSwatchColorsOnPlP";






export type OrdermeOffersType = {
  featured: {
    id: number;
    name: string;
    products: ProductWidgetsType[];
    url_path: string;


  }[]
}
// eslint-disable-next-line import/no-default-export
export default function OrdermeOffers(props: OrdermeOffersType) {

  const { featured } = props;
  if (!featured || featured.length === 0)
    return null;

  return (
    <div className="w-full bg-light-80 box-border pb-10 pt-3">
      <MainContentLayout className="md:!mt-0 box-border mx-auto xl:w-[1170px] mt-0 max-xl:w-auto w-auto max-md:mt-0 max-md:mx-[.6rem]">

        <div className="flex items-center text-primary-500 max-992:mb-4">
          <IconCheck2Circle width={42} height={42} className="ml-1" />
          <h2 className="text-[18px] font-bold max-md:font-semibold">
            <Trans id="Ordme suggestions to your taste" />
          </h2>
        </div>
        {featured.map((category, index) => (
          <div className={`${index === 0 ? 'max-md:mt-0' : ''} mt-5 !max-md:mx-4 mx-auto w-auto max-992:mx-auto max-992:w-[720px] max-xl:w-[944px] max-md:w-auto`} key={category.name}>
            <Link href={`/${category?.url_path}`} className="font-bold text-primary-500 text-base mb-5 inline-block max-992:mr-4 max-md:mb-3">
              {category?.name}
            </Link>
            <div className="w-full max-w-[100%] max-992:overflow-x-auto max-xl:m-auto max-992:w-auto">
              <div className="max-992:w-fit grid grid-cols-5 gap-x-[6px] pb-2 w-[84rem] 992:w-auto max-xl:flex-wrap max-992:justify-normal max-xl:justify-center max-xl:flex gap-1 max-992:flex-nowrap">
                {category?.products?.map((product) => (
                  <div key={product?.id} className="bg-white relative shadow-sm hover:shadow-md transition-shadow border  border-solid border-light-50 flex flex-col items-center p-2  max-xl:w-[300px] max-992:w-[250px]">
                    <Link href={`/p/${product?.url_key}`}>
                      <Image width={200} height={200} src={product?.image?.url} alt="" />
                    </Link>
                    <ShowSwatchColorsOnPLP configurable_options={product?.color} />
                    <Link href={`/p/${product?.url_key}`} className="text-sm text-center text-default-900 transition-colors hover:text-primary-500 font-semibold">
                      {product?.name}
                    </Link>
                    <div className="!w-full flex flex-col items-center mt-3">
                      <span className="text-sm text-secondary-300 font-bold">
                        <Money {...product?.price_range?.minimum_price?.final_price} />
                      </span>
                      <span className="text-sm text-light-400 line-through">
                        {product?.price_range?.minimum_price?.discount?.percent_off > 0 && <Money {...product?.price_range?.minimum_price?.regular_price} />}

                      </span>
                    </div>

                    {product?.price_range?.minimum_price?.discount?.percent_off > 0 &&
                      <span className="w-9 h-9 bg-error-500 absolute left-2 top-2 text-center text-[12px] text-white font-bold flex items-center justify-center rounded-full rounded-br-none">
                        %{Math.round(product?.price_range?.minimum_price?.discount?.percent_off)}</span>}
                  </div>
                ))}
              </div>
            </div>

          </div>
        ))}


      </MainContentLayout>
    </div>
  )
}