import Script from 'next/script'

export default function IndexSchema() {
  return (
    <>
      <Script id='schema-organization' strategy='beforeInteractive' type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'ordme',
          alternateName: 'اردمی',
          url: process.env.NEXT_PUBLIC_SITE_URL,
          logo: `${process.env.NEXT_PUBLIC_SITE_URL}/logo.svg`,
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+989016932911',
            contactType: 'customer service',
            areaServed: 'IR',
            availableLanguage: 'Persian',
          },
          sameAs: [
            'https://www.x.com/ordme',
            'https://www.facebook.com/ormdeofficial/',
            'https://www.aparat.com/ordme',
            'https://youtube.com/ordme',
          ],
        })}
      </Script>
    </>
  )
}
