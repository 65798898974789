import { Hidden } from "@mui/material";
import { ProductWidgetsType } from "../../../../api/landingPage/widgets";
import MainContentLayout from "../../../customLayout/mainContentLayout";
import SalesContent from "./SalesContent";
import SalesSide, { SalesSideType } from "./SalesSide";


type SalesType = {
  mostDiscountedWidget: ProductWidgetsType[]
  verticalFeatured: SalesSideType['verticalFeatured']


}
export default function Sales(props: SalesType) {

  return (
    <MainContentLayout 
      className="mx-auto md:!pb-10 md:!pt-3 1170:max-w-1170 992:max-w-970 !mt-0 max-1170:!max-w-[90%] max-md:min-w-[98%] max-md:mx-0 max-xl:w-[60rem] max-992:w-[45rem] max-md:w-full !m-auto max-[1315px]:!mx-5 max-xl:!m-auto"
    >
      <div className="grid grid-flow-col gap-4 m-auto w-[95%] mb-8 md:w-full max-md:w-auto">
        <Hidden mdDown>
          <SalesSide verticalFeatured={props?.verticalFeatured} />
        </Hidden>
        <SalesContent mostDiscountedWidget={props?.mostDiscountedWidget} />
      </div>
    </MainContentLayout>

  )
}