import { Image } from "@graphcommerce/image";
import { Money } from "@graphcommerce/magento-store";
import { i18n } from "@lingui/core";
import Link from "next/link";
import { ProductWidgetsType } from "../../../../api/landingPage/widgets";
import MainContentLayout from "../../../customLayout/mainContentLayout";
import IconBellOutline from "../../../icons/IconBellOutline";





export default function HomeWidgets(widgetsData: any) {
  const widgets = [
    {
      title: i18n._('Best sellers of the day'),
      data: widgetsData?.widgetsData?.data?.bestSellingWidget

    },
    {
      title: i18n._('The most popular of the week'),
      data: widgetsData?.widgetsData?.data?.popularWidget
    }
  ]
  console.log({widgets})
  return (
    <div
      className="1170:max-w-1170 max-w-970 mx-auto py-3"
    >
      {widgets.map((item, index) => item?.data?.length ? (
        <div key={item.title} className={`${index > 0 && 'mt-10 mb-5'}`}>
          <div className="text-primary-500 flex items-center mb-2" >
            <IconBellOutline width={40} height={40} className="ml-2" />
            <h3 className="text-xl font-semibold">{item.title}</h3>
          </div>
          <div className="w-full max-w-[100%] overflow-x-auto max-992:mx-auto">
            <div className="max-992:flex grid grid-cols-6 md:grid-cols-3 gap-2.5 w-[84rem] md:w-auto max-md:gap-1">
              {item?.data?.map((product: ProductWidgetsType) => (
                <div key={product?.id} className="max-992:flex max-992:!flex-col max-992:items-center max-992:  bg-white relative shadow-sm hover:shadow-md transition-shadow border border-solid border-light-50 flex flex-col md:flex-row items-stretch p-2 max-992:min-w-[260px]">
                  <Link href={`/p/${product?.url_key}`} className="max-992:h-auto max-992:w-auto max-992:border-none md:border border-solid border-light-100 h-[120px] w-[120px] relative flex items-center justify-center max-md:w-webkit-fill-available">
                    <Image width={100} height={100} className="max-md:w-webkit-fill-available" src={product?.image?.url} alt={product?.name}/>
                    {product?.price_range?.minimum_price?.discount?.percent_off > 0 &&
                      <span className="w-7 h-7 bg-error-500 absolute left-1.5 top-1.5 text-center text-xs text-white flex items-center justify-center rounded-full rounded-br-none">
                        %{Math.round(product?.price_range?.minimum_price?.discount?.percent_off)}
                      </span>}
                  </Link>

                  <div className="flex flex-col flex-1 justify-start pr-2">
                    <Link href={`/p/${product?.url_key}`} className="text-sm text-default-900 transition-colors hover:text-primary-500">
                      {product?.name}
                    </Link>

                    <div className="w-full flex 992:justify-start items-center mt-3 justify-center max-992:flex-col">

                      <span className="text-base 992:text-secondary-500 text-secondary-300 font-semibold ml-1">
                        <Money {...product?.price_range?.minimum_price?.final_price} />
                      </span>
                      <span className="text-sm text-light-400 line-through">
                        {product?.price_range?.minimum_price?.discount?.percent_off > 0 && <Money {...product?.price_range?.minimum_price?.regular_price} />}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
      ):<></>
      )}

    </div>

  )
}