import { useEffect, useState, useRef } from 'react';
import { Pagination, EffectFade, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import Link from 'next/link';
import { Image } from "@graphcommerce/image";
import { AdsBannerImagesType } from './AdsBannerImages';


export default function AdsSlider(props: AdsBannerImagesType) {
  const { images, className } = props;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const paginationRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const updatePaginationHeight = () => {
      if (paginationRef.current) {
        const swiperSlides = document.querySelectorAll('.swiper-slide img');
        if (swiperSlides.length > 0) {
          const slideHeight = swiperSlides[0].clientHeight;
          // paginationRef.current.style.height = `${slideHeight}px`;
        }
      }
    };

    updatePaginationHeight();
    window.addEventListener('resize', updatePaginationHeight);
    return () => window.removeEventListener('resize', updatePaginationHeight);
  }, [images]);

  return (
    <div className={`${className}`}>
      <Swiper
        modules={[Pagination, EffectFade, Navigation, Autoplay]}
        loop
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
        }}
        navigation={!isMobile}
        slidesPerView={1}
        spaceBetween={30}
        effect="fade"
        className="rounded-2xl md:h-full relative"
        autoplay={{ delay: 5000 }}
      >
        {images && images.length > 0 && images.map((item) => (
          <SwiperSlide key={item?.image} className='h-full'>
            <Link href={item?.link ?? '#'} className='rounded-2xl overflow-hidden inline-block w-full h-full relative'>
              <Image width={500} height={655} alt="" src={item?.image ?? '#'} />
            </Link>
          </SwiperSlide>
        ))}
        <div ref={paginationRef} className={`swiper-pagination gap-x-2 absolute bottom-4 right-4 left-auto justify-start ${isMobile ? '' : 'left-1/2 transform -translate-x-1/2'} flex`}></div>
      </Swiper>
      <style jsx global>{`
        .swiper-button-next,
        .swiper-button-prev {
          background: none !important;
          box-shadow: none !important;
        }
      `}</style>
    </div>
  );
}
