export type VideoSpecificType = {
  id: string
  title: string
  link: string
  img: string
}
export class VideoItemProcessor {
  id: VideoSpecificType['id']
  title: VideoSpecificType['title']
  link: VideoSpecificType['link']
  img: VideoSpecificType['img']

  constructor(data: Record<string, any>) {
    this.id = data?.id ?? null
    this.title = data?.title?.rendered ?? null
    this.link = data?.link ?? null
    this.img = data?.yoast_head_json?.og_image?.at(0)?.url?.replace("https://demo.","https://") ?? null
  }

  static processItems(data: Record<string, any>[]): VideoItemProcessor[] {
    return data.map((item) => new VideoItemProcessor(item))
  }
}
