import htmlToText from './htmlToText'

export type ArticleSpecificType = {
  id: string
  title: string
  link: string
  body: string
  img: string
}
export class ArticleItemProcessor {
  id: ArticleSpecificType['id']
  title: ArticleSpecificType['title']
  link: ArticleSpecificType['link']
  body: ArticleSpecificType['body']
  img: ArticleSpecificType['img']

  constructor(data: Record<string, any>) {
    this.id = data?.id ?? null
    this.title = data?.title?.rendered ?? null
    this.link = data?.link ?? null
    this.body =
      htmlToText(data?.content?.rendered)
        .slice(0, 102)
        .concat('...') ?? null
    this.img = data?.yoast_head_json?.og_image?.at(0)?.url?.replace("https://demo.","https://") ?? null
  }

  static processItems(data: Record<string, any>[]): ArticleItemProcessor[] {
    return data.map((item) => new ArticleItemProcessor(item))
  }
}
