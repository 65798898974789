import { Trans } from "@lingui/react";
import { Hidden } from "@mui/material";
import { ProductWidgetsType } from "../../../../api/landingPage/widgets";
import DiscountSelection from "./DiscountSelection";


export type SalesContentType = {
  mostDiscountedWidget: ProductWidgetsType[]
}

// eslint-disable-next-line import/no-default-export
export default function SalesContent(props: SalesContentType) {
  const { mostDiscountedWidget } = props;
  return (
    <div className="col-span-4 md:col-span-3  flex flex-col gap-2">
      <Hidden mdDown>
        <div className="border flex justify-between border-solid  border-light-150 shadow-md p-3 bg-[#f6f6f6] max-992:hidden">
          <div className="flex items-center flex-col flex-1 px-5">
            <div className="w-11 h-11 bg-[url('/om-services.png')]" style={{ backgroundPositionX: "-49px" }} />
            <p className="text-xs font-medium text-primary-500 mb-0 ">
              <Trans id="Send orders with safe packaging" />
            </p>
          </div>
          <div className="flex items-center flex-col flex-1 px-5">
            <div className="w-10 h-10 bg-[url('/om-services.png')]" style={{ backgroundPositionX: "124px" }} />
            <p className="text-xs font-medium text-primary-500 mb-0 ">
              <Trans id="Legal supply of original and authentic products" />
            </p>
          </div>

          <div className="flex items-center flex-col flex-1 px-5">
            <div className="w-10 h-10 bg-[url('/om-services.png')]" style={{ backgroundPositionX: "-140px" }} />
            <p className="text-xs font-medium text-primary-500 mb-0 ">
              <Trans id="7 days possibility to return goods" />
            </p>
          </div>
        </div>
      </Hidden>

      <DiscountSelection mostDiscountedWidget={mostDiscountedWidget} />
    </div>
  )
}