import { Maybe, SelectedConfigurableOption } from "@graphcommerce/graphql-mesh"
import Image from "next/image";
import { useState, useEffect } from "react";

type ShowSwatchColorsOnPLPType = {
  configurable_options: Array<Maybe<SelectedConfigurableOption>>;
}

function ShowSwatchColorsOnPLP(props: ShowSwatchColorsOnPLPType) {
  const [count, setCount] = useState<string[]>([])
  useEffect(() => {
    const newCount: string[] = [];
    if (props?.configurable_options?.length) {
      props?.configurable_options?.forEach(option => {
        option?.values?.forEach(item => {
          if (item?.swatch_data && item?.swatch_data?.__typename === "ImageSwatchData") {
            newCount.push(item?.swatch_data?.thumbnail as string);
          }
        });
      });
    }
    setCount(newCount);
  }, [props.configurable_options]);

  return (<div className="w-full flex flex-row gap-2 justify-center mb-11">
    {count.map((item, index) => {
      if (index < 4)
        return <Image key={item} src={item} width={20} height={20} alt='' className="rounded-[50%]" />;
      if (index === 5)
        return <p key={item} className="w-[20px] h-[20px] border border-[#ddd] rounded-full">{`${count.length - 4}+`}</p>;
      return null;
    })}
  </div>)
}

// eslint-disable-next-line import/no-default-export
export default ShowSwatchColorsOnPLP;

