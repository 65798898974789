import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs))
}

export function convertPersianToEnglishDate(persianDate: string) {
  const persianToEnglishMap = {
    '۰': '0',
    '۱': '1',
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
    '/': '/',
  }

  return persianDate
    .split('')
    .map((char) => persianToEnglishMap[char] || char)
    .join('')
}

export const identityHtmlAndStyleTagRegex = /<style[^>]*>.*?<\/style>|<html[^>]*>|<\/html>|<[^>]+>/g
export const removeHtmlAndStyleTag = (value: string | undefined): string =>
  value?.replace(identityHtmlAndStyleTagRegex, '') || ''

export const uuid = () => {
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  return template.replace(/[xy]/g, function (char) {
    const random = (Math.random() * 16) | 0
    const value = char === 'x' ? random : (random & 0x3) | 0x8
    return value.toString(16)
  })
}
