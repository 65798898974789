import Image from "next/image"
import Link from "next/link"

const socialItems = [
  {
    image: '/static-image/Home/instagram_banner.jpg',
    alt: "instagram ordme",
    href: "https://www.instagram.com/ordmecom"
  },

  {
    image: '/static-image/Home/aparat_banner.jpg',
    alt: "aparat ordme",
    href: "https://www.aparat.com/ordme"
  }
];


export type SalesSideType = {
  verticalFeatured: {
    "id": number,
    "name": string,
    "url_path": string
  }[]
}

// eslint-disable-next-line import/no-default-export
export default function SalesSide(props: SalesSideType) {
  const { verticalFeatured } = props;
  return (
    <div className="col-span-[224px] flex flex-col justify-between items-center gap-2.5 max-992:hidden">
      {socialItems?.map((item) => (
        <Link key={item.alt} href={item.href} target="_blank" className="border w-full border-solid border-light-150 transition-transform hover:-translate-y-0.5 shadow-md hover:shadow-default-900/60">
          <Image width={224} height={84} src={item.image} alt={item.alt} />
        </Link>
      ))}
      <div className="outline outline-offset-0 outline-light-170 outline-1 w-full bg-[#f6f6f6] flex flex-col shadow-md ">
        <ul className="gap-[1px] grid">
          {verticalFeatured?.map((item) => (
            <li className="w-full hover:bg-light-170" key={item.name}>
              <h2>
                <Link href={item.url_path} className="text-center text-ordme-gray-400  w-full block box-border p-[14px]  transition-colors text-sm">{item.name}</Link>
              </h2>
            </li>
          ))}

        </ul>

      </div>
    </div>
  )
}